import PuDropshipContainer from "../components/PuDropship/PuDropshipContainer";

const PuDropship = (props) => {
  return (
    <div className="container">
      <div className="">
        <PuDropshipContainer />
      </div>
    </div>
  );
};

export default PuDropship;
